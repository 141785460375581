<template>
  <div class="classProductClass">
    <van-nav-bar
        style="background: #e9e9ef;"
        title="资料库"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
    />
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(image,index) in oneData" :key="index" v-if="image.type==1">
        <img style="height: 10rem" :src="getPic(image.url)" />
      </van-swipe-item>
    </van-swipe>
    <P style="text-indent: 2em;font-size: .4rem;padding: .32rem;color: #969697">
      {{description}}
    </P>

<!--    <div style="margin: 0 .3rem 0 .3rem" @click="showPic">-->
<!--      <label style="font-size: .5rem;margin-bottom: .5rem;display: inline-block;">产品图</label>-->
<!--      <div class="boxMain" v-for="row in oneData" v-if="row.type==1">-->
<!--        <div style="width: 30%;display: inline-block;"><img width="100%" height="100px" style="border-radius: .3rem" :src="getPic(row.url)" /></div>-->
<!--        <div style="width: 60%;display: inline-block;padding-left: .5rem;font-size: .5rem;vertical-align:top;margin-top: .5rem">-->
<!--          <p>{{row.prefix}}</p>-->
<!--          <p style="color: #646566;font-size: .4rem;">{{row.suffix}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div style="margin: 0 .32rem 0 .32rem" @click="showPic">
      <label style="font-size: .53rem;margin-bottom: .55rem;display: inline-block;line-height: .64rem;vertical-align: middle">
      <div style="font-size: .53rem;display: inline-block;position: relative;top:.05rem;">产品图</div>
      </label>
      <div class="boxMain" style="padding: .33rem">
        <div style="width: 33.33333%;display: inline-block;" v-for="row in oneData" v-if="row.type==1"><img width="95%" height="100px" style="border-radius: .3rem;display: inline-block;" :src="getPic(row.url)" /></div>
<!--        <div style="width: 60%;display: inline-block;padding-left: .5rem;font-size: .5rem;vertical-align:top;margin-top: .5rem">-->
<!--          <p>{{row.prefix}}</p>-->
<!--          <p style="color: #646566;font-size: .4rem;">{{row.suffix}}</p>-->
<!--        </div>-->
      </div>
    </div>

    <div style="margin: 0 .32rem 0 .32rem">
      <label style="font-size: .53rem;margin-bottom: .55rem;display: inline-block;line-height: .64rem;vertical-align: middle">
        <div style="font-size: .53rem;display: inline-block;position: relative;top:.05rem;">说明书（操作手册）</div>
      </label>
      <div class="boxMain">
        <div class="box2" style="width: 8.8rem;margin-left: 50%;position: relative;left: -4.4rem;height: 2.43rem" @click="showPdf(row)" v-for="row in oneData" v-if="row.type==2">
        <div style="width: 20%;display: inline-block;"><img style="border-radius: .3rem;height: 1.48rem;margin: .35rem .1rem .6rem .19rem" src="../img/pdf.png" /></div>
        <div style="width: 70%;display: inline-block;padding-left: .1rem;font-size: .37rem;vertical-align:top;margin-top: .5rem">
          <p style="color: #333333">{{row.prefix}}</p>
          <div style="height: .2rem;">&nbsp;</div>
          <p style="color: #999999;font-size: .32rem;">{{row.suffix}}</p>
        </div>
        </div>
      </div>
    </div>

    <div style="margin: 0 .32rem 0 .32rem">
      <label style="font-size: .53rem;margin-bottom: .55rem;display: inline-block;line-height: .64rem;vertical-align: middle">
        <div style="font-size: .53rem;display: inline-block;position: relative;top:.05rem;">产品彩页</div>
      </label>
      <div class="boxMain">
        <div class="box2" style="width: 8.8rem;margin-left: 50%;position: relative;left: -4.4rem;height: 2.43rem" @click="showPdf(row)" v-for="row in oneData" v-if="row.type==3">
          <div style="width: 20%;display: inline-block;"><img style="border-radius: .3rem;height: 1.48rem;margin: .35rem .1rem .6rem .19rem" src="../img/pdf.png" /></div>
          <div style="width: 70%;display: inline-block;padding-left: .1rem;font-size: .37rem;vertical-align:top;margin-top: .5rem">
            <p style="color: #333333">{{row.prefix}}</p>
            <div style="height: .2rem;">&nbsp;</div>
            <p style="color: #999999;font-size: .32rem;">{{row.suffix}}</p>
          </div>
        </div>
      </div>
    </div>

    <div style="margin: 0 .32rem 0 .32rem">
      <label style="font-size: .53rem;margin-bottom: .55rem;display: inline-block;line-height: .64rem;vertical-align: middle">
        <div style="font-size: .53rem;display: inline-block;position: relative;top:.05rem;">升级固件</div>
      </label>
      <div class="boxMain">
        <div class="box2" style="width: 8.8rem;margin-left: 50%;position: relative;left: -4.4rem;height: 2.43rem" v-for="row in oneData" v-if="row.type==4">
          <a :href="getPic(row.url)">
          <div style="width: 20%;display: inline-block;"><img style="border-radius: .3rem;height: 1.48rem;margin: .35rem .1rem .6rem .19rem" src="../img/rar.png" /></div>
          <div style="width: 70%;display: inline-block;padding-left: .1rem;font-size: .37rem;vertical-align:top;margin-top: .5rem">
            <p style="color: #333333">{{row.prefix}}</p>
            <div style="height: .2rem;">&nbsp;</div>
            <p style="color: #999999;font-size: .32rem;">{{row.suffix}}</p>
          </div>
          </a>
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <van-popup style="width: 100%;" v-model="show">
      <van-swipe indicator-color="white">
        <van-swipe-item v-for="(image,index) in oneData" :key="index" v-if="image.type==1">
          <img style="height:10rem"  :src="getPic(image.url)" />
        </van-swipe-item>
      </van-swipe>
    </van-popup>

    <van-popup style="width: 100%;" v-model="showPdf1">
      <van-swipe indicator-color="white">
        <van-swipe-item>
          <iframe :src="getPic2(oneRow)" width="100%" height="600px"></iframe>
        </van-swipe-item>
      </van-swipe>
    </van-popup>

  </div>
</template>

<script>
import classificationApi from "@/utils/classificationApi";
import TEMPURL from "@/utils/tempurl";

export default {
  name: "classProduct",
  // components:{
  //   pdf
  // },
  data(){
    return{
      description:localStorage.getItem("description"),
      id:localStorage.getItem("id"),
      // 每一条数据
      oneData:'',
      show: false,
      showPdf1: false,
      // pdf
      oneRow: '',
    }
  },
  created() {
    // 获取页面初始数据
    this.getParams()
  },
  methods:{
    //pdf
    // getObjectURL() {
    //   let url = null;
    //   //这里是重点，然后将流数据转换为url，CMapReaderFactory方法在进行处理
    //   url = pdf.createLoadingTask({ url: this.getPic(this.oneRow), CMapReaderFactory });
    //   return url;
    // },
    getPic(pic){
      return TEMPURL+pic
    },
    getPic2(pic){
      return TEMPURL+pic
    },
    showPic(){
      this.show = true
    },
    showPdf(row){
      this.oneRow=row.url
      // this.showPdf1 = true
      window.open(this.getPic2(row.url),'_blank')
    },
    // 返回
    onClickLeft() {
      history.go(-1)
    },
    async getParams(){
      // this.id=this.$route.params.id
      // this.description=this.$route.params.description
      let params={
        id:this.id
      }
      const { data,code } = await classificationApi.getimgData(params)
      if (code === 200){
        this.oneData=data
      }
    }
  },
}
</script>

<style lang="less" scoped>
.classProductClass{
  background: #fff;
  padding-bottom: 2rem;
  .box2{
    border-bottom: 1px dotted #CCCCCC;
  }
  .box2:last-of-type{
    border-bottom: none;
  }
  label::before{
    content: "";
    margin-right: 0.12rem;
    display: inline-block;
    vertical-align: middle;
    width: 0.11rem;
    height: 0.53rem;
    background: #4161EC;
    border-radius: 0.05rem;
  }
  ::v-deep.van-icon{
    color: #323233;
    font-size: .6rem;
  }

  .boxMain{
    //height: 2.43rem;
    border-radius: .3rem;
    -webkit-box-shadow: 0px 0px 16px #e4e4e4;
    //padding: .35rem .49rem .6rem .49rem;
    margin-bottom: .76rem;
  }

}
</style>